// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <div style={{ background: "#0d1b2a" }}>
      <Container maxWidth="lg">
        {/* Text Block */}
        <Grow in={true} timeout={1200} appear={true}>
          <Box mt={3}>
            <Typography variant="h3" paragraph>
              A World of Gaming at Your Fingertips{" "}
            </Typography>
            <Typography variant="h4" paragraph>
              Our library boasts a wide range of games that cater to every taste
              and skill level:
            </Typography>
            <Typography variant="body1" paragraph>
              Action & Adventure: Embark on epic quests and thrilling missions.{" "}
              <br />
              Puzzles & Board Games: Test your wits with our mind-bending
              puzzles.
              <br />
              Sports & Racing: Get your adrenaline pumping with high-speed
              action.
              <br />
              Strategy & RPG: Craft your legacy with strategic gameplay and
              role-playing adventures.
              <br />
              Casual Games: Relax, unwind, and enjoy hours of fun.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/games"
            >
              Go to games
            </Button>


            <Typography
              variant="h4"
              paragraph
              fontWeight="900"
              textAlign="right"
            >
              18+
            </Typography>
            <Typography
              variant="h6"
              paragraph
              fontWeight="900"
              textAlign="right"
            >
              {" "}
              All games are intended for a mature audience. By accessing and
              using this website, users declare that they are of legal age to
              participate in the entertainment provided.
            </Typography>
          </Box>
        </Grow>
      </Container>
    </div>
  );
}

export default CTA;
