// src/components/Hero.js
import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { hero } from "../assets";
import Grow from "@mui/material/Grow";

function Hero() {
  return (
    <Box
      height="80vh"
      display="flex"
      flexDirection="column"
      style={{
        backgroundColor: "#333652",
        background: `radial-gradient(circle, rgba(4,3,25, .7) 0%, rgba(16,45,78,.9) 100%), url(${hero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
        <Grow in={true} timeout={1200}>
          <Box margin="0 auto" maxWidth="sm" p={4} display="flex" height="100%" flexDirection="column" justifyContent="center"   
 alignItems="center" textAlign="center">
            <Typography variant="h3" gutterBottom style={{ fontWeight: "900" }}>
             Play online games for free
            </Typography>
            <Typography variant="h4">
            Our library offers a wide range of games that cater to every taste and skill level
            </Typography>
          </Box>
        </Grow>
    </Box>
  );
}

export default Hero;
