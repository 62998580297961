import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import games from '../data/games.js';
import { useNavigate } from 'react-router-dom'; 

export default function MyList() {
  const navigate = useNavigate(); 

  const savedList = localStorage.getItem('myList');
  const myList = savedList ? JSON.parse(savedList) : [];

  return (
    <div style={{marginTop: "auto"}}>
      <Container maxWidth="md">
      {myList.length > 0 ? (
        <><Typography variant="h1" sx={{
            fontSize: {
              xs: '2.7rem', // slightly larger font size on small devices
              sm: '3rem', // slightly larger font size on small devices
              md: '6rem', // default h1 font size on medium devices and above
            }}} textAlign="center" marginBottom="2rem">
                      Your saved games:
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
                          {myList.map(gameId => {
                              const game = games.find(g => g.id === gameId);
                              return (
                                <Box key={game.id}  sx={{ width: 'auto', p: 1 }}>
                                      <Card sx={{
                                            transition: 'transform 0.3s',
                                            '&:hover': {
                                            transform: 'scale(1.05)',
                                            },
                                            textAlign: "center",
                                            backgroundColor: "primary.main500"
                                        }}>
                                        <CardActionArea onClick={() => navigate(`/game/${game.id}`)}>
                                          <CardMedia
                                              component="img"
                                              alt={game.title}
                                              height="240"
                                              image={game.image}
                                              title={game.title} />
                                          <CardContent>
                                              <Typography gutterBottom variant="h5" component="h2">
                                                  {game.title}
                                              </Typography>
                                              {/* Additional game details can go here */}
                                          </CardContent>
                                          </CardActionArea>

                                      </Card>
                                  </Box>
                              );
                          })}
                      </Box>
                      </>
      ) : (
        <Typography variant="h2" textAlign="center">
          There is nothing here yet. Add games to the list to see them here.
        </Typography>
      )}
      </Container>
    </div>
  );
}
