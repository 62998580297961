import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      dark: "#0d1b2a", // Deep navy, analogous to original dark shade
      main: "#1b3a5b", // Rich primary blue
      main600: "#0d1b2a", // Deep navy, akin to original
      main500: "#16324c", // Mid-dark navy
      main400: "#1e4467", // Useful for gradients or shadows, darker blue
      main300: "#1b3a5b", // Mid shade of primary blue
      main200: "#4a6fa1", // Lighter mid shade, softer blue
      main100: "#a8c0e0", // Near light shade, pale blue
      main50: "#cae4fb", // Very light shade, almost sky blue
    },
    text: {
      primary: "#E9EAEC", // Keeping text color for contrast
    },
    secondary: {
      main: "#62a8ea", // Bright blue, replacing yellow
      secondary: "#90adc6", // Soft blue, replacing the previous secondary
    },
    success: {
      main: "#C8E4B2",
    },
  },
  typography: {
    fontFamily: "Righteous, sans-serif",
    body1: {
      fontSize: "18px",
    },
    h3: {
      fontSize: "1.7rem",
      "@media (min-width:600px)": {
        fontSize: "3rem",
      },
    },
    h4: {
      fontSize: "1.4rem",
      "@media (min-width:600px)": {
        fontSize: "2rem",
      },
    },
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
